@tailwind base;
@tailwind components;
@tailwind utilities;

.notistack-Snackbar div[role=alert] {
  display: block;
}

.notification-text p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.flatfile-close-button {
  background-color: grey;
  top: 40px !important;
}

.react-datepicker__time-container {
  width: 7rem !important;
}

.react-datepicker__time-box {
  margin: 0px !important;
  width: 100% !important;
}

.react-datepicker__time-list-item {
  font-size: 14px !important;
  padding: 12px !important;
  height: auto !important;
  font-family: "Inter var" !important;
}

.react-datepicker__time-list-item--selected {
  background-color: rgb(79 70 229) !important;
}